import React, { useState } from 'react'
import styled from 'styled-components'

import SwiperCore from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'

import AurecPlaceCard from 'components/aurec-place-card'

import { AurecPlaceCardProps } from 'components/aurec-place-card'

import media from 'styles/media'
import { breakpoints } from 'styles/theme'

const StyledWrapper = styled.div`
  margin-top: 70px;
  padding: 0 20px;

  ${media.lg.min} {
    padding: 0 50px;
  }

  ${media.xl.min} {
    margin-top: 150px;
    max-width: 1100px;
    margin-left: auto;
    margin-right: auto;
  }

  .swiper {
    overflow: visible;
  }

  ${media.lg.min} {
    .swiper-slide {
      opacity: 0.3;
      transition: 0.35s;
    }

    .swiper-slide-active,
    .swiper-slide-next,
    .swiper-slide-next + .swiper-slide {
      opacity: 1;
    }
  }
`

const StyledArrowControls = styled.div`
  position: absolute;
  top: -40.5px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    display: block;
    background-color: #e6e6e6;
    height: 1px;
    top: 50%;
    left: 0;
    right: 0;
    z-index: -1;
  }
`

const StyledControlButton = styled.button`
  z-index: 1;
  width: 65px;
  height: 65px;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  cursor: pointer;
  transition: 0.3s;

  @media (hover) {
    &:hover {
      background-color: ${({ theme }) => theme.colors.orange};
      path {
        fill: ${({ theme }) => theme.colors.white};
      }
    }
  }
`

type AurecPlacesProps = {
  places: Array<AurecPlaceCardProps>
}

const AurecPlaces: React.FC<AurecPlacesProps> = ({ places }) => {
  const [swiper, setSwiper] = useState<SwiperCore | null>(null)

  const goPrev = () => {
    swiper?.slidePrev()
  }

  const goNext = () => {
    swiper?.slideNext()
  }

  return (
    <StyledWrapper>
      <Swiper
        slidesPerView={1}
        grabCursor
        spaceBetween={20}
        onSwiper={(initSwiper: SwiperCore) => setSwiper(initSwiper)}
        // loop
        onSlideChange={() => {
          console.log(swiper)
        }}
        breakpoints={{
          [breakpoints.lg]: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
      >
        {places.map((el, index) => {
          return (
            <SwiperSlide key={`${el.name}+${index}`}>
              <AurecPlaceCard
                name={el.name}
                location={el.location}
                image={el.image}
                icon={el.icon}
                key={el.name}
              />
            </SwiperSlide>
          )
        })}
      </Swiper>

      <StyledArrowControls>
        <StyledControlButton
          aria-label="poprzedni slajd"
          onClick={() => {
            goPrev()
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="20.001"
            viewBox="0 0 11 20.001"
          >
            <path
              id="arrow"
              d="M259.633,6538.71a1.062,1.062,0,0,1-1.464,0l-8.563-8.264a1.951,1.951,0,0,1,0-2.828l8.625-8.325a1.063,1.063,0,0,1,1.454-.01.976.976,0,0,1,.011,1.425l-7.894,7.617a.975.975,0,0,0,0,1.414l7.831,7.558a.974.974,0,0,1,0,1.413"
              transform="translate(-248.999 -6519.001)"
              fill="#ff9d00"
              fill-rule="evenodd"
            />
          </svg>
        </StyledControlButton>
        <StyledControlButton
          aria-label="następny slajd"
          onClick={() => {
            goNext()
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="11"
            height="20.001"
            viewBox="0 0 11 20.001"
          >
            <path
              id="arrow"
              d="M249.366,6538.71a1.062,1.062,0,0,0,1.464,0l8.563-8.264a1.951,1.951,0,0,0,0-2.828l-8.625-8.325a1.063,1.063,0,0,0-1.454-.01.976.976,0,0,0-.011,1.425l7.894,7.617a.975.975,0,0,1,0,1.414l-7.831,7.558a.974.974,0,0,0,0,1.413"
              transform="translate(-249 -6519.001)"
              fill="#ff9d00"
              fill-rule="evenodd"
            />
          </svg>
        </StyledControlButton>
      </StyledArrowControls>
    </StyledWrapper>
  )
}

export default AurecPlaces
