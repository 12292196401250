import React from 'react'
import styled from 'styled-components'

import media from 'styles/media'

import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'

const StyledDot = styled.div`
  position: absolute;
  background: ${({ theme }) => theme.colors.white};
  bottom: 0;
  width: 15px;
  height: 15px;
  border: 1px solid ${({ theme }) => theme.colors.orange};
  border-radius: 50%;
  transition: 0.3s;
`

const StyledTitle = styled.p`
  text-align: center;
  font-size: 24px;
  color: ${({ theme }) => theme.colors.black};
  margin-top: 20px;
  margin-bottom: 10px;
  transition: 0.3s;
`

const StyledWrapper = styled.div<{ simpleDot?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5;
  user-select: none;

  padding-bottom: 35px;

  ${media.lg.min} {
    padding-bottom: ${({ simpleDot }) => (simpleDot ? '30px' : '50px')};
  }

  &:hover ${StyledDot} {
    background-color: ${({ theme }) => theme.colors.orange};
  }
  &:hover ${StyledTitle} {
    color: ${({ theme }) => theme.colors.orange};
  }
`

const StyledImageWrapper = styled.div`
  position: relative;

  ${media.md.min} {
    max-width: 300px;
  }
`

const StyledIconWrapper = styled.div`
  position: absolute;
  top: 25px;
  left: 25px;
  width: 80px;
  height: 80px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
`

const StyledSubtitle = styled.p`
  text-align: center;
`

const StyledGatsbyImage = styled(GatsbyImage)`
  width: 100%;
  height: 0;
  padding-top: 100%;

  img {
    border-radius: 34px;
  }
`

import fitnessIcon from 'assets/icons/fitness-icon.svg'
import fountainsIcon from 'assets/icons/fountains-icon.svg'
import outdoorsIcon from 'assets/icons/outdoors-icon.svg'

const Icon: React.FC<{ icon: string }> = ({ icon }) => {
  switch (icon) {
    case 'siłownia':
      return <img src={fitnessIcon} alt="" />
    case 'zieleń':
      return <img src={outdoorsIcon} alt="" />
    case 'fontanna':
      return <img src={fountainsIcon} alt="" />

    default:
      return <img src={fitnessIcon} alt="" />
  }
}

export type AurecPlaceCardProps = {
  image: {
    src: IGatsbyImageData
    alt: string
  }
  name: string
  location: string
  icon: string
  simpleDot?: boolean
}

const AurecPlaceCard: React.FC<AurecPlaceCardProps> = ({
  image,
  name,
  location,
  icon,
  simpleDot,
}) => {
  return (
    <StyledWrapper simpleDot={simpleDot}>
      <StyledImageWrapper>
        <StyledGatsbyImage image={image.src} alt={image.alt} />
        <StyledIconWrapper>
          <Icon icon={icon} />
        </StyledIconWrapper>
      </StyledImageWrapper>
      <StyledTitle>{name}</StyledTitle>
      <StyledSubtitle>{location}</StyledSubtitle>
      <StyledDot />
    </StyledWrapper>
  )
}

export default AurecPlaceCard
